import { Component, OnInit, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
  pseudo: string = "Nelitec";
  public isSticky: boolean = false;
  public isToggled: boolean = false;
  @ViewChild('electricityTrigger', {static: false}) electricityTrigger: MatMenuTrigger;

  constructor(navbarService: NavbarService) {
    navbarService.myData.subscribe((pseudo) => {
      this.pseudo = pseudo;
    })
  }

  ngOnInit() {

  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 40;
  }

}
