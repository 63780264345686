import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';

@Component({
  selector: 'app-electricite',
  templateUrl: './electricite.component.html',
  styleUrls: ['./electricite.component.scss']
})
export class ElectriciteComponent implements OnInit {
  public imageUrls: (string | IImage)[] = [
    { url: '/assets/elec/diapo1.png',},
    { url: '/assets/elec/diapo2.png'},
    { url: '/assets/elec/diapo3.png'},
    //{ url: '/assets/elec/diapo3.png', caption: 'Apple TV'},
    { url: '/assets/elec/diapo4.png',}
  ];
  constructor() { }
  ngOnInit() {
  }

}
