import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public scroll : number = 0;
  public sectionsTotalNumber : number = 8;
  public bouncer = [
    false,
    false,
    false,
    false,
    false,
    false
  ];
  constructor(private navbarService: NavbarService, private renderer: Renderer2) {
    
  }

  ngOnInit() {
    this.renderer.listen(window, 'scroll', ($event) => {
      this.scroll = (window.scrollY / this.sectionsTotalNumber);
      console.log("scroll: " + this.scroll);
    });
  }

  updatePseudo(str: string) {
    this.navbarService.updatePseudo(str);
  }

  getHCS() {
    return HomeComponentStructure;
  }
}


export enum HomeComponentStructure {
  Domotique,
  Automatisme,
  Antenne,
  VDI,
  Securite,
  Climatisation
}
