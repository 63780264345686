import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatIconModule, MatMenuModule } from '@angular/material';
import { LabelIconComponent } from './components/label-icon/label-icon.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NavbarService } from './components/navbar/navbar.service';
import { DomotiqueComponent } from './components/domotique/domotique.component';
import { ElectriciteComponent } from './components/electricite/electricite.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {SlideshowModule} from 'ng-simple-slideshow';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    PageNotFoundComponent,
    LabelIconComponent,
    DomotiqueComponent,
    ElectriciteComponent,
    ContactComponent,
    FooterComponent
  ],
  imports: [
    SlideshowModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatGridListModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, NavbarService],
  bootstrap: [AppComponent]
})
export class AppModule { }
