import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public myData: BehaviorSubject<string> = new BehaviorSubject<string>('Nelitec');
  pseudo: string = "Nelitec";

  constructor() { }

  updatePseudo(str: string) {
    this.myData.next(str);
  }

}
