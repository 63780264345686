import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { DomotiqueComponent } from './components/domotique/domotique.component';
import { ElectriciteComponent } from './components/electricite/electricite.component';
import { ContactComponent } from './components/contact/contact.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'domotique', component: DomotiqueComponent},
  { path: 'electricite', component: ElectriciteComponent},
  { path: 'contact', component: ContactComponent},
  { path: '',
    redirectTo: '/home', 
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
